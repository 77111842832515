import { getWSService } from "./services/WebSocket";

import * as React from "react";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import GpsOffIcon from "@mui/icons-material/GpsOff";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsIcon from "@mui/icons-material/Directions";

export default function AlignItemsList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userListMessageHandler = (message) => {
    console.log("userListMessageHandler", message);
    console.log("userListMessageHandler", data);
    const newdata = data.map((item) => item);
    newdata.unshift(JSON.parse(message));
    setData(newdata);
  };

  console.log("AlignItemsList");

  useEffect(() => {
    getWSService().addMessageListener("", "userlist", userListMessageHandler);
  }, [data]);

  useEffect(() => {
    fetch(
      `https://wxs1qf2k68.execute-api.us-west-2.amazonaws.com/Stage/reports/getGpsReportsHandler`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((actualData) => {
        setData(actualData);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        // setLoading(false);
        // getWSService().addMessageListener(
        //   "",
        //   "userlist",
        //   userListMessageHandler
        // );
      });
  }, []);
  const getEventIcon = (event = null) => {
    // console.log(event);
    switch (event) {
      case "31":
        return <MonitorHeartIcon color="secondary" fontSize="large" />;
        break;
      case "35":
        return <AccessTimeIcon color="secondary" fontSize="large" />;
        break;
      case "32":
        return <DirectionsIcon color="secondary" fontSize="large" />;
        break;
      case "33":
        return <AddRoadIcon color="secondary" fontSize="large" />;
        break;
      case "34":
        return <LocationOnIcon color="secondary" fontSize="large" />;
        break;
      case "20":
        return <ArrowCircleDownIcon color="secondary" fontSize="large" />;
        break;
      case "21":
        return <ArrowCircleUpIcon color="secondary" fontSize="large" />;
        break;
      case "24":
        return <GpsOffIcon color="secondary" fontSize="large" />;
        break;
      case "25":
        return <GpsFixedIcon color="secondary" fontSize="large" />;
        break;
      default:
        return <QuestionMarkIcon color="secondary" fontSize="large" />;
        break;
    }
  };
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {data &&
        data.map((report, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start" key={index}>
              <ListItemIcon>{getEventIcon(report.event)}</ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body1"
                      color="text.secondary"
                    >
                      {new Date(report.generatedAt).toString()}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {report.event}
                    </Typography>
                    {report.command}

                    <Link
                      target="_blank"
                      rel="noopener"
                      href={`https://www.google.com/maps/search/?api=1&query=${report.latitude}%2C${report.longitude}`}
                    >
                      Open Google Maps
                    </Link>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
    </List>
  );
}
