import WebSocket from "isomorphic-ws";

const WS_URL = "wss://3zv7szrdq4.execute-api.us-west-2.amazonaws.com/Prod";

let WSService = null;

class WebSocketService {
  constructor() {
    this.websocket = null;
    this.messageListeners = [];
    this.isOpen = false;
  }

  /**
   *  Set up WebSocket connection for a new user and
   *  basic listeners to handle events
   */
  initSocket = () => {
    console.log("initSocket");
    this.websocket = new WebSocket(WS_URL);
    this.websocket.onopen = this.onConnOpen;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onclose = this.onConnClose;
  };

  /**
   *  Show connection status to user
   */
  onConnOpen = () => {
    this.isOpen = true;
    console.log("Websocket connected!");
  };

  /**
   *  Log lost connection for now
   */
  onConnClose = () => {
    console.log("Websocket closed!");
  };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   *  message {
   *    room,
   *    type,
   *    msg,
   *    username,
   *    for
   *  }
   */
  sendMessage = (routeKey, message) => {
    if (this.websocket && this.isOpen) {
      this.websocket.send(
        JSON.stringify({
          rcaction: routeKey,
          rcmsg: JSON.stringify(message),
        })
      );
    } else {
      console.log(`Websocket connection not found!!`);
    }
  };

  /**
   *  Used by application to register different listeners for
   *  different message types [To be used later]
   *  @param room Room name
   *  @param type Message type ['all', 'pm']
   *  @param listener Function to handle message type
   */
  addMessageListener = (room, type, listener) => {
    // if (!type || !room || typeof listener !== "function") {
    //   return;
    // }
    this.messageListeners[0] = {
      room,
      type,
      listener,
    };
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body
   * @param data Message body received from WebSocket
   */
  onMessage = (data) => {
    console.log("onMessage", data.data);
    // if (data) {
    //   const message = data.data;

    this.messageListeners[0].listener(data.data);
    // console.log("onMessage", this.messageListeners);
    // for (const listener of this.messageListeners) listener.listener(data.data);

    //   if (typeListener && typeof typeListener.listener === "function") {
    //     typeListener.listener(message);
    //   } else {
    //     console.log("No handler found for message type");
    //   }
    // }
  };

  static initWSService() {
    if (!WSService) {
      WSService = new WebSocketService();
      WSService.initSocket();
      return WSService;
    }

    return WSService;
  }
}

// WebSocketService.initWSService();

export const getWSService = WebSocketService.initWSService;
