import logo from "./logo.svg";
import "./App.css";
import AlignItemsList from "./AlignItemsList";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const theme = createTheme();

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlignItemsList className="App-header" key="AlignItemsList" />
      </ThemeProvider>
    </div>
  );
}

export default withAuthenticator(App);
